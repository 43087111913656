<template>
  <div id="AddBankDrawer">
    <SDrawer title="添加银联卡" :visible.sync="visible" @opened="onOpened">
      <el-form
        class="add_bank_drawer_form"
        label-position="top"
        :model="unionPayForm"
        status-icon
        :rules="unionPayRules"
        ref="unionPayForm"
      >
        <el-form-item :label="$t('common.field.bankName')" prop="bankName">
          <el-select
            id="bankName"
            v-model="unionPayForm.bankName"
            :placeholder="$t('common.field.select')"
            data-testid="bankName"
          >
            <el-option
              v-for="(list, index) in bankNameLists"
              :key="index"
              :label="list"
              :value="list"
              :data-testid="list"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('common.field.cardHolderName')" prop="cardHolderName">
          <el-input id="name" maxlength="128" v-model="unionPayForm.cardHolderName"></el-input>
        </el-form-item>
        <el-form-item :label="$t('common.field.nationalId')" prop="nationalId">
          <el-input id="nationalId" maxlength="20" v-model="unionPayForm.nationalId"></el-input>
        </el-form-item>
        <el-form-item :label="$t('common.field.bankCardNumber')" prop="cardNumber">
          <el-input id="cardNumber" maxlength="20" v-model="unionPayForm.cardNumber"></el-input>
        </el-form-item>
        <el-form-item :label="$t('common.field.reservedMobileNumber')" prop="cardPhoneNumber">
          <el-input id="cardPhoneNumber" maxlength="20" v-model="unionPayForm.cardPhoneNumber"></el-input>
        </el-form-item>
        <el-form-item :label="$t('common.field.bankBranch')" prop="branchName" class="special_select_form_item">
          <div data-toggle="distpicker" :class="{ error: isError }">
            <select v-model="unionPayForm.province"></select>
            <select v-model="unionPayForm.city"></select>
          </div>
          <el-input
            id="branchName"
            v-model="unionPayForm.branch"
            :placeholder="$t('common.field.bankBranch')"
          ></el-input>
        </el-form-item>
        <el-form-item label="银行卡" prop="cardPhotoFile">
          <vUpload :limit="1" v-on:updateFileInfo="updateFileInfo" selfText class="form_upload">
            <el-button type="primary">单击上传</el-button>
            <template slot="warnTip">
              <p>{{ this.$t('common.field.updateCardPhoto') }}</p>
            </template>
          </vUpload>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="submitForm('unionPayForm')" data-testid="submitUnionPayForm">
          {{ $t('common.button.submit') }}
        </el-button>
      </div>
    </SDrawer>
  </div>
</template>

<script>
import vUpload from '@/components/vUpload'
import { apiRegisterUnionPayCard_cp } from '@/resource'
import '@/assets/js/distpicker_admin'
import { validateNumber, valiAlphanumeric } from '@/util/validation'
export default {
  name: 'AddBankDrawer',
  props: ['onSuccess'],
  data() {
    return {
      visible: false,
      unionPayForm: {
        bankName: '',
        cardNumber: '',
        cardPhoneNumber: '',
        cardHolderName: '',
        province: '',
        city: '',
        branchName: '',
        nationalId: '',
        cardPhotoFile: '',
        branch: '',
      },
      unionPayRules: {
        bankName: [
          {
            required: true,
            message: this.$t('common.formValidation.bankNameReq'),
            trigger: 'change',
          },
        ],
        cardNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.cardReq'),
            trigger: 'blur',
          },
          {
            validator: validateNumber,
            trigger: 'blur',
          },
        ],
        cardPhoneNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.phoneNumberReq'),
            trigger: 'blur',
          },
          {
            validator: validateNumber,
            trigger: 'blur',
          },
        ],
        cardHolderName: [
          {
            required: true,
            message: this.$t('common.formValidation.cardOnNameReq'),
            trigger: 'blur',
          },
        ],
        branchName: [
          {
            required: true,
            message: this.$t('common.formValidation.bankBranchReq'),
            trigger: 'blur',
          },
          {
            validator: (rule, value, callback) => {
              if (!this.unionPayForm.branch) {
                const message = this.$t('common.formValidation.bankBranchReq')
                callback(new Error(message))
              }
              callback()
            },
            trigger: 'blur',
          },
        ],
        nationalId: [
          {
            required: true,
            message: this.$t('common.formValidation.nationaIdReq'),
            trigger: 'blur',
          },
          {
            validator: valiAlphanumeric,
            trigger: 'blur',
          },
        ],
        cardPhotoFile: [
          {
            required: true,
            message: this.$t('common.formValidation.filePhotoReq'),
            trigger: 'blur',
          },
        ],
      },
      bankNameLists: [
        '工商银行',
        '建设银行',
        '交通银行',
        '农业银行',
        '中国银行',
        '招商银行',
        '光大银行',
        '兴业银行',
        '中信银行',
        '民生银行',
        '广东发展银行',
        '深圳发展银行',
        '中国邮储银行',
        '浦发银行',
        '平安银行',
        '北京银行',
        '上海银行',
        '南京银行',
        '渤海银行',
        '长沙银行',
        '浙商银行',
        '杭州银行',
        '华夏银行',
        '宁波银行',
        '深圳农商行',
        '北京农商银行',
        '上海农村商业银行',
        '浙江稠州商业银行',
        '中国农村信用社',
      ],
      resultMessage: this.$t('paymentDetails.result.unionPayCardSuccessMessage'),
      isError: '',
    }
  },
  components: {
    vUpload,
  },
  computed: {
    compoundProperty() {
      return this.unionPayForm.province && this.unionPayForm.city
    },
  },
  watch: {
    compoundProperty(bool) {
      if (bool) this.isError = ''
    },
    'unionPayForm.province'(value) {
      this.unionPayForm.city = ''
      this.unionPayForm.branchName = `${this.unionPayForm.province}-${this.unionPayForm.city}-${this.unionPayForm.branch}`
    },
    'unionPayForm.city'(value) {
      this.unionPayForm.branchName = `${this.unionPayForm.province}-${this.unionPayForm.city}-${this.unionPayForm.branch}`
    },
    'unionPayForm.branch'(value) {
      this.unionPayForm.branchName = `${this.unionPayForm.province}-${this.unionPayForm.city}-${this.unionPayForm.branch}`
    },
  },
  methods: {
    submitForm(formName) {
      const vm = this
      this.$refs[formName].validate(valid => {
        if (vm.unionPayForm.province == '' || vm.unionPayForm.city == '') {
          vm.isError = 'error'
          return false
        }

        if (valid) {
          this.registerCard(this.unionPayForm)
            .then(resp => {
              console.log('resp', resp)
              if (resp.data.code == 0) {
                this.$emit('onSuccess', {
                  resultMessage: this.resultMessage,
                })
              } else {
                return false
              }
            })
            .catch(err => {
              this.$message({
                message: err,
                type: 'error',
              })
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    updateFileInfo(fileInfo) {
      this.unionPayForm.cardPhotoFile = fileInfo.fileList[0]
      this.$refs['unionPayForm'].validateField('cardPhotoFile')
      console.log('file path', this.unionPayForm.cardPhotoFile)
    },
    registerCard(card) {
      console.log('card body: ', card)
      return apiRegisterUnionPayCard_cp(card)
    },
    onOpened() {
      $('[data-toggle="distpicker"]').distpicker()
    },
  },
}
</script>

<style lang="scss" scoped>
.add_bank_drawer_form {
  .el-form-item {
    .el-select {
      width: 100%;
    }
  }
  .special_select_form_item {
    .el-form-item__content {
      select {
        width: 48%;
        height: 32px;
        padding: 0 15px;
        font-size: 15px;
        color: rgb(98, 109, 113);
        margin-bottom: 5px;
        border-style: solid;
        border-width: $border-width;
        border-color: $border-color;
        border-radius: 6px;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;

        @include rtl-sass-value(
          background,
          $white url(~@/assets/images/menu/down.png) no-repeat 90% center,
          $white url(~@/assets/images/menu/down.png) no-repeat 10% center
        );

        &:nth-child(-n + 1) {
          @include rtl-sass-prop(margin-right, margin-left, calc((100% - 48% * 2)));
        }

        &:nth-child(2) {
          @include rtl-sass-value(float, right, left);
        }

        /deep/ option {
          border-radius: 8px;
          background-color: $white;
          box-shadow: 0px 0px 14.72px 1.28px rgba(11, 16, 122, 0.09);
          font-size: 16px;
          line-height: 54px;
          color: $black;
        }
      }

      .error {
        select {
          border: 1px solid $light-red !important;
        }
      }
    }
  }
  /deep/ .form_upload {
    .el-upload {
      padding: 0;
      border: none;
      background-color: transparent;
      display: flex;
    }
    .el-upload__tip {
      padding-top: 8px;
    }
  }
}
</style>
