<template>
  <div id="BankDetail">
    <SDrawer title="查看详情" :visible.sync="visible" :showFooterConfirm="false" custom-class="bank_card_detail_drawer">
      <el-form label-position="top" v-if="type == 1">
        <el-form-item :label="$t('common.field.name')">
          <span v-html="detailData.card_holder_name"></span>
        </el-form-item>
        <el-form-item :label="$t('common.field.ccNum')">
          <span>{{ detailData.card_number }}</span>
        </el-form-item>
        <el-form-item :label="$t('common.field.expDate')">
          <span v-html="detailData.expiry"></span>
        </el-form-item>
      </el-form>
      <el-form label-position="top" v-if="type == 4">
        <el-form-item :label="$t('common.field.bankName')">
          <span v-html="detailData.bank_name"></span>
        </el-form-item>
        <el-form-item :label="$t('common.field.bankCardNumber')">
          <span v-html="handleFormatCardNumber(detailData.card_number)"></span>
        </el-form-item>
        <el-form-item :label="$t('common.field.bankBranch')">
          <span v-html="detailData.branch_name"></span>
        </el-form-item>
        <el-form-item :label="$t('common.field.cardHolderName')">
          <span v-html="detailData.card_holder_name"></span>
        </el-form-item>
        <el-form-item :label="$t('common.field.reservedMobileNumber')">
          <span v-html="detailData.card_phone_number"></span>
        </el-form-item>
        <el-form-item :label="$t('common.field.nationalId')">
          <span v-html="detailData.national_id_card"></span>
        </el-form-item>
        <el-form-item :label="$t('common.field.cardPhoto')">
          <img class="cardPhoto" :src="detailData.card_photo_path" alt />
        </el-form-item>
      </el-form>
    </SDrawer>
  </div>
</template>

<script>
import { apiGetCardDetials } from '@/resource'
export default {
  name: 'BankDetail',
  data() {
    return {
      cardId: null,
      type: null,
      detailData: {},
      visible: false,
    }
  },
  methods: {
    openDrawer(row) {
      this.visible = true
      this.type = row.payment_type
      if (this.type == 4) {
        this.cardId = row.id
        this.queryCard().then(card => {
          this.detailData = card
        })
      } else if (this.type == 1) {
        this.detailData = row
      }
    },
    queryCard() {
      return apiGetCardDetials({ cardId: this.cardId, cardType: 'UNIONPAY_CARD_CPS' }).then(resp => {
        if (resp.data.code == 0) {
          return resp.data.data
        } else {
          return []
        }
      })
    },
    handleFormatCardNumber(num) {
      if (num) {
        return num.substr(0, 6) + '******' + num.substr(num.length - 4)
      }
      return false
    },
  },
}
</script>

<style lang="scss" scoped>
.cardPhoto {
  width: 150px;
  height: 100px;
}
</style>
